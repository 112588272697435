import styled from "styled-components"
import Img from "gatsby-image"

export const Container = styled.article``

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

export const Wrapper = styled.div`
  flex: 1;
  @media screen and (min-width: 1024px) {
    flex: ${props => (props.thumb ? 1 : "0 0 490px")};
  }
`

export const Title = styled.h3`
  font-weight: bold;
  font-size: 38px;
  line-height: 48px;

  margin: 0;

  @media screen and (min-width: 1024px) {
    font-size: 144px;
    line-height: 192px;
  }
`

export const Subtitle = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 24px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 81px;
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: inherit;
`

export const Description = styled.h4`
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  margin: 24px 0;

  @media screen and (min-width: 1024px) {
    font-size: 36px;
    line-height: 48px;
    margin: 0 0 24px;
  }
`

export const Thumb = styled(Img)``
