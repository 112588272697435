import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import List from "~/components/List"
import {
  Container,
  ContainerWrapper,
  Wrapper,
  Title,
  Subtitle,
  Link,
  Description,
  Thumb,
} from "./styles"

const ItemBig = ({ data, children }) => {
  const image = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "staffpro.png" }) {
        childImageSharp {
          fluid(maxWidth: 827) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Title>{data?.title}</Title>
      <Subtitle>
        <Link href={data?.url} title={data?.title} target="_blank">
          {data?.subtitle}
        </Link>
      </Subtitle>

      <ContainerWrapper>
        <Wrapper thumb>
          <Thumb
            alt={data.title}
            fluid={image.placeholderImage.childImageSharp.fluid}
          />
        </Wrapper>

        <Wrapper>
          <Description
            dangerouslySetInnerHTML={{ __html: data.description }}
          ></Description>
          <List data={data?.list} title="Stack" />

          {children}
        </Wrapper>
      </ContainerWrapper>
    </Container>
  )
}

ItemBig.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ItemBig
