import React from "react"
import { Carousel } from "react-responsive-carousel"
import { useIntl } from "gatsby-plugin-intl"

import Card from "~/components/Card"
import Projects from "~/components/projects"
import Section from "~/components/Section"

import { Container } from "./styles"
import padaria from "~/images/projects/padaria-brasileira-extranet.png"
import suabreja from "~/images/projects/sua-breja.png"
import vss from "~/images/projects/vss.png"

const data = [
  {
    title: "Padaria Brasileira",
    description:
      "Brazilian bakery <br> <small>Private management system.</small>",
    list: ["Angular", "RXJS"],
    image: padaria,
  },
  {
    title: "Sua breja App",
    description: "Craft <strong>Beers.</strong>",
    url: "https://play.google.com/store/apps/details?id=com.mobipdv.suabreja",
    urlPreview: "suabreja.com",
    list: ["Ionic", "Angular", "NGRX", "Konva JS"],
    image: suabreja,
  },
  {
    title: "Vale Saúde <br> Sempre App",
    description: "Getting you close to a doctor.",
    url: "https://valesaudesempre.com.br/",
    urlPreview: "valesaudesempre.com.br",
    list: ["Angular", "Ionic"],
    image: vss,
    full: true,
  },
]

const carousel = [data[1], data[0], data[2]]

const MiddleBottom = ({ ...rest }) => {
  const intl = useIntl()

  data[0].description = intl.formatHTMLMessage({ id: "padaria_description" })
  data[1].description = intl.formatHTMLMessage({ id: "suabreja_description" })
  data[2].description = intl.formatHTMLMessage({ id: "vss_description" })

  return (
    <Container {...rest}>
      <Projects top>
        {data.map((item, index) => (
          <Card key={index} item={item} small full={!!item.full} />
        ))}
      </Projects>

      <Section>
        <Carousel
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          infiniteLoop={true}
        >
          {carousel.map((item, index) => (
            <Card key={index} item={item} small />
          ))}
        </Carousel>
      </Section>
    </Container>
  )
}

export default MiddleBottom
