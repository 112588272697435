import React from "react"
import { Carousel } from "react-responsive-carousel"
import { useIntl } from "gatsby-plugin-intl"

import Card from "~/components/Card"
import Projects from "~/components/projects"
import Section from "~/components/Section"

import { Container } from "./styles"

import todxs from "~/images/projects/todxs.png"
import myralis from "~/images/projects/myralis.png"

const data = [
  {
    title: "TODXS",
    description:
      "For an <strong>inclusive</strong> and <br> <strong>discrimination-free</strong> country.",
    url: "https://www.todxs.org",
    urlPreview: "todxs.org",
    list: [
      "React",
      "Gatsby",
      "NodeJS",
      "PostgreSQL",
      "Redis",
    ],
    image: todxs,
  },
  {
    title: "Myralis",
    description: "Total <strong>dedication</strong> to lives.",
    url: "https://myralis.com.br",
    urlPreview: "myralis.com.br",
    list: ["Angular", "Angular Universal", "NGRX"],
    image: myralis,
  },
]

const MiddleTop = ({ ...rest }) => {
  const intl = useIntl()

  data[0].description = intl.formatMessage({ id: "todxs_description" })
  data[1].description = intl.formatMessage({ id: "myralis_description" })

  return (
    <Container {...rest}>
      <Projects>
        {data.map((item, index) => (
          <Card key={index} item={item} />
        ))}
      </Projects>

      <Section>
        <Carousel
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          infiniteLoop={true}
        >
          {data.map((item, index) => (
            <Card key={index + "-mobile"} item={item} />
          ))}
        </Carousel>
      </Section>
    </Container>
  )
}

export default MiddleTop
