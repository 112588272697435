import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import Section from "~/components/Section"
import List from "~/components/List"
import background from "~/images/docloc-bg.png"

import {
  Container,
  Title,
  Subtitle,
  Link,
  Description,
  Thumb,
  ContainerThumb,
} from "./styles"

const list = ["Angular", "Angular Universal", "NGRX"]

const Docloc = ({ ...rest }) => {
  const intl = useIntl()

  const image = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "docloc.png" }) {
        childImageSharp {
          fluid(maxWidth: 946, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container style={{ backgroundImage: `url(${background})` }} {...rest}>
      <Section>
        <Title>DOCLOC</Title>

        <Subtitle>
          <Link href="https://docloc.com.br" title="DOCLOC" target="_blank">
            docloc.com.br
          </Link>
        </Subtitle>

        <Description
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: "docloc_description",
              defaultMessage:
                "Your <strong>clinic</strong> anywhere. <small>Thankful patients everywhere.</small>",
            }),
          }}
        />

        <List data={list} title="Stack" />

        <ContainerThumb>
          <Thumb
            alt="DOCLOC - Seu consultório em qualquer lugar"
            fluid={image.placeholderImage.childImageSharp.fluid}
          />
        </ContainerThumb>
      </Section>
    </Container>
  )
}

export default Docloc
