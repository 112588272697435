import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Container, Title, Description, Link, Wrapper, Thumb } from "./styles"
import List from "~/components/List"

const Item = ({ data }) => {
  const image = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "bitalert.png" }) {
        childImageSharp {
          fluid(maxWidth: 794) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Wrapper thumb>
        <Thumb
          alt={data.title}
          fluid={image.placeholderImage.childImageSharp.fluid}
        />
      </Wrapper>

      <Wrapper>
        <Title>
          {data.title}

          <Link href={data.url} title={data.title} target="_blank">
            <small>{data.subtitle}</small>
          </Link>
        </Title>

        <Description>{data.description}</Description>

        <List data={data.list} title="Stack" />
      </Wrapper>
    </Container>
  )
}

Item.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Item
