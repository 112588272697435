import React from "react"

import { Background, Title, SubTitle, SubTitleLink } from './styles'

import bg from "~/images/projects/deco-bg.png"

const Top = ({ ...rest }) => (
  <>
    <div { ...rest }>
      <Background src={bg} alt="Deco Designs" loading="lazy" />
    </div>

    <div className="l-container" { ...rest }>
      <Title>
        Deco <br />
        Designs
      </Title>

      <SubTitle>
        Build a rug.
      </SubTitle>

      <SubTitleLink href="https://buildarug.decodesigns.ie/" title="buildarug.decodesigns.ie" target="_blank">
        buildarug.decodesigns.ie
      </SubTitleLink>
    </div>
  </>
)

export default Top
