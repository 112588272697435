import styled from "styled-components"

export const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  color: #E8E8E8;
  margin-top: 48px;
  font-size: 50px;
  line-height: 68px;
  
  @media screen and (min-width: 1024px) {
    margin-top: 142px;
    font-size: 214px;
    line-height: 220px;
  }
`

export const SubTitle = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 58px;
  margin-top: 30px;
  margin-bottom: 0;

  @media screen and (min-width: 1024px) {
    font-size: 110px;
    line-height: 147px;
  }
`

export const SubTitleLink = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: inherit;
  text-decoration: none;
`

export const Background = styled.img`
  margin-left: auto;
  margin-right: auto;
  display: block;

  @media screen and (min-width: 1024px) {
    margin-top: 144px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
