import React from "react"
import PropTypes from "prop-types"

import { Container, Title } from "./styles"

const List = ({ data, title }) => (
  <>
    <Title>{title}</Title>
    <Container>
      {data?.map(item => (
        <li key={item}>{item}</li>
      ))}
    </Container>
  </>
)

List.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
}

List.defaultProps = {
  title: ``,
}

export default List
