import styled from "styled-components"
import Img from "gatsby-image"

export const Container = styled.article`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

export const Wrapper = styled.div`
  flex: 1;

  @media screen and (min-width: 1024px) {
    flex: ${props => (props.thumb ? 1 : "0 0 522px")};
    margin-right: ${props => (props.thumb ? "20px" : 0)};
  }
`

export const Title = styled.h3`
  font-weight: bold;
  font-size: 38px;
  line-height: 48px;
  margin: 30px 0 0px;

  @media screen and (min-width: 1024px) {
    font-size: 96px;
    line-height: 128px;
    margin: 0;
  }

  small {
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    margin-left: 8px;
  }
`

export const Description = styled.h4`
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;

  margin: 4px 0 26px;

  @media screen and (min-width: 1024px) {
    font-size: 36px;
    line-height: 48px;
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: inherit;
`

export const Thumb = styled(Img)``
