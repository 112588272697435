import React from "react"
import PageTransition from "gatsby-plugin-page-transitions"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "~/components/Section"
import Bitalert from "~/components/projects/Bitalert"
import Staffpro from "~/components/projects/Staffpro"
import Docloc from "~/components/projects/Docloc"
import PIT from "~/components/projects/PIT"
import Middle from "~/components/Middle"
import Deco from "~/components/projects/Deco"

const IndexPage = () => {
  const intl = useIntl()

  return (
    <PageTransition>
      <Layout>
        <SEO
          title="Cauê Almeida - Fullstack developer"
          description={intl.formatMessage({ id: "description" })}
        />

        <Section first data-sal="fade">
          <Bitalert />
        </Section>

        <Section data-sal="fade">
          <PIT />
        </Section>

        <Deco data-sal="fade" />

        <Section data-sal="fade">
          <Staffpro />
        </Section>

        <Docloc data-sal="fade" />

        <Middle data-sal="fade" />
      </Layout>
    </PageTransition>
  )
}

export default IndexPage
