import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import Item from "~/components/Item"

const data = {
  title: "Bitalert",
  description:
    "Never lose crypto values again. Just set up the price you want to be notified and that's it.",
  subtitle: "bitalert.app",
  url: "https://bitalert.app/",
  list: [
    "React Native",
    "React Redux",
    "React Saga",
    "Socket",
    "Styled Components",
  ],
}

const Bitalert = () => {
  const intl = useIntl()

  data.description = intl.formatMessage({ id: "bitalert_description" })

  return <Item data={data} />
}

export default Bitalert
