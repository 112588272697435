import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import ItemBig from "~/components/ItemBig"
import Credits from "~/components/Credits"

const data = {
  title: "Staff PRO",
  description:
    "Are you a freelancer looking for a job at a big event <br> such as lollapalooza? <br> Just subscribe.",
  subtitle: "staffpro.com.br",
  url: "https://staffpro.com.br/",
  list: ["Ionic", "Capacitor", "Angular", "NGRX"],
}

const Staffpro = () => {
  const intl = useIntl()

  data.description = intl.formatMessage({ id: "staffpro_description" })

  return (
    <>
      <ItemBig data={data}>
        <Credits />
      </ItemBig>
    </>
  )
}

export default Staffpro
