import styled from "styled-components"

export const Container = styled.section`
  display: flex;
  margin-left: -25px;
  margin-right: -25px;
  flex-wrap: wrap;

  article {
    flex: 1;
    margin: 0 25px;
  }
`
