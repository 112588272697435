import React from "react"
import PropTypes from "prop-types"

import { Container } from "./styles"

const Text = ({ children, margin, ...rest }) => (
  <Container margin={margin} {...rest}>
    {children}
  </Container>
)

Text.propTypes = {
  children: PropTypes.node.isRequired,
  margin: PropTypes.bool,
}

Text.defaultProps = {
  margin: false,
}

export default Text
