import styled from "styled-components"
import Img from "gatsby-image"

export const Container = styled.div`
  position: relative;

  @media screen and (min-width: 1024px) {
    margin-top: 300px;
    min-height: 1000px;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  transform: rotate(5deg);

  max-width: 80%;
  margin: 40px auto;
  
  @media screen and (min-width: 1024px) {
    position: absolute;
    top: 0;
    right: -170px;
    max-width: 365px;
    margin: 0;
  }
  
  @media screen and (min-width: 1166px) {
    max-width: 565px;
    top: -130px;
  }
`

export const Thumb = styled(Img)``

export const Title = styled.h3`
  font-weight: bold;
  font-size: 38px;
  line-height: 48px;
  margin: 0;

  @media screen and (min-width: 1024px) {
    font-size: 144px;
    line-height: 192px;
  }
`

export const Subtitle = styled.a`
  font-size: 20px;
  line-height: 27px;
  margin: 0;
  color: inherit;
  text-decoration: none;
`

export const Description = styled.h4`
  font-weight: normal;
  max-width: 490px;
  font-size: 22px;
  line-height: 30px;
  margin: 24px 0;

  @media screen and (min-width: 1024px) {
    font-size: 36px;
    line-height: 48px;
    margin: 70px 0 24px;
  }
`
