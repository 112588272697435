import React from "react"

import { Container } from "./styles"

import Top from "~/components/projects/Deco/Top"
import Post from "~/components/projects/Deco/Post"

const Deco = ({ ...rest }) => {
  return (
    <Container>

      <Top {...rest} />
      <Post {...rest} />

    </Container>
  )
}

export default Deco
