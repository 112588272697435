import styled from "styled-components"

export const Container = styled.ul`
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
  }
`

export const Title = styled.h5`
  margin: 0;
  font-weight: bold;

  font-size: 30px;
  line-height: 42px;

  @media screen and (min-width: 1024px) {
    font-size: 36px;
    line-height: 48px;
  }
`
