import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"

import { Container, Title, Subtitle, Description, Thumb, Wrapper } from "./styles"
import List from "~/components/List"

const PIT = () => {
  const image = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "petintime.png" }) {
        childImageSharp {
          fluid(maxWidth: 794) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const intl = useIntl()
  const data = {
    title: "Pet in Time",
    urlText: 'petintime.com.br',
    url: 'https://petintime.com.br',
    list: [
      'React Native',
      'React Redux',
      'React Saga',
      'NodeJS',
      'Redis',
      'MongoDB',
      'AWS'
    ],
  }

  return (
    <Container>
      <Title>
        {data.title}
      </Title>

      <Subtitle href={data.url} title={data.title} target='_blank'>
        {data.urlText}
      </Subtitle>

      <Wrapper>
        <Thumb
          alt={data.title}
          fluid={image.placeholderImage.childImageSharp.fluid}
        />
      </Wrapper>

      <Description>
        {intl.formatMessage({ id: "pit_description" })}
      </Description>

      <List data={data?.list} title="Stack" />
    </Container>
  )
}

export default PIT
