import styled from "styled-components"

export const CreditsSection = styled.div`
  margin-top: 40px;
`

export const CreditsTitle = styled.h6`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0;
`

export const CreditsText = styled.p`
  a {
    color: inherit;
    text-decoration: none;
    margin-bottom: 0;
  }
`
