import styled from "styled-components"

export const Container = styled.div`
  & > section:first-child {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  & > section:last-child {
    margin-top: 96px;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
`
