import styled from "styled-components"
import Img from "gatsby-image"

export const Container = styled.article`
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);

  @media screen and (max-width: 1023px) {
    margin-top: ${props => (props.full ? "50px !important" : 0)};
    flex: ${props => (props.full ? "0 0 calc(100% - 50px) !important" : 1)};
  }

  &:hover {
    & > div:first-child,
    & > img:first-child {
      transform: scale(1.5) rotate(10deg);
      filter: blur(5px);
    }

    & > div {
      opacity: 1;
    }
  }

  h5 {
    font-size: 24px;
    line-height: 32px;
    margin-top: auto;

    @media screen and (min-width: 1200px) {
      margin-top: initial;
    }
  }

  ul {
    font-size: 18px;
    line-height: 24px;
  }
`

export const Thumb = styled(Img)`
  transition: all 0.2s ease;
`

export const Image = styled.img`
  transition: all 0.2s ease;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  filter: blur(0);
`

export const Wrapper = styled.div`
  padding: 26px;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-light);
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 0.72);
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  opacity: 0;
`

export const Title = styled.h3`
  font-weight: bold;
  font-size: ${props => (props.small ? "36px" : "40px")};
  line-height: ${props => (props.small ? "40px" : "46px")};
  margin: 0;

  @media screen and (min-width: 1200px) {
    font-size: ${props => (props.small ? "36px" : "72px")};
    line-height: ${props => (props.small ? "40px" : "80px")};
  }
`

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  @media screen and (min-width: 1200px) {
    font-size: 20px;
    line-height: 27px;
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: inherit;
`

export const Description = styled.h4`
  font-weight: normal;
  font-size: ${props => (props.small ? "24px" : "26px")};
  line-height: ${props => (props.small ? "32px" : "36px")};
  margin: ${props => (props.small ? "26px 0 0" : "14px 0 0")};
  flex: 1;
  display: none;

  @media screen and (min-width: 1200px) {
    font-size: ${props => (props.small ? "24px" : "36px")};
    line-height: ${props => (props.small ? "32px" : "48px")};
    margin: ${props => (props.small ? "26px 0 0" : "30px 0 0")};
    display: block;
  }

  small {
    display: block;
    font-size: 18px;
    line-height: 24px;
  }
`
