import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import { CreditsSection, CreditsText, CreditsTitle } from "~/components/Credits/styles"

const Credits = () => {
  const intl = useIntl()

  return (
    <CreditsSection>
      <CreditsTitle>
        {intl.formatMessage({ id: "credits_title" })}
      </CreditsTitle>

      <CreditsText>
        Design by <a href="https://www.instagram.com/renatosuares/" title="Renato Dangello"
                     target="_blank">@Renato Dangello</a>
      </CreditsText>
    </CreditsSection>
  )
}

export default Credits
