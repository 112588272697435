import React from "react"
import Text from "~/components/Text"
import MiddleBottom from "./MiddleBottom"
import MiddleTop from "./MiddleTop"

import { FormattedMessage } from "gatsby-plugin-intl"

const Middle = ({ ...rest }) => (
  <>
    <MiddleTop {...rest} />
    <MiddleBottom {...rest} />
    <Text margin {...rest}>
      <FormattedMessage id="more" />
    </Text>
  </>
)

export default Middle
