import React from "react"
import PropTypes from "prop-types"
import Section from "~/components/Section"

import { Container } from "./styles"

const Projects = ({ children, top }) => (
  <Section marginBottomLess top={top}>
    <Container>{children}</Container>
  </Section>
)

Projects.propTypes = {
  children: PropTypes.node.isRequired,
  top: PropTypes.bool,
}

Projects.defaultProps = {
  top: false,
}

export default Projects
