import styled from "styled-components"
import Img from "gatsby-image"

export const Container = styled.article`
  height: auto;
  background-size: cover;
  background-position: center;
  color: var(--color-smoked);
  padding-top: 96px;
  padding-bottom: 96px;

  @media screen and (min-width: 600px) {
    height: 1040px;
    font-size: 144px;
    padding-bottom: 0;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 376px;
  }

  @media screen and (min-width: 1558px) {
    overflow: initial;
  }

  section {
    margin-top: 0;
    max-width: 1050px;

    @media screen and (max-width: 1023px) {
      margin-bottom: 0;
    }
  }
`

export const Title = styled.h3`
  font-weight: bold;
  font-size: 38px;
  line-height: 48px;
  margin: 0;

  @media screen and (min-width: 1024px) {
    font-size: 144px;
    line-height: 192px;
  }
`

export const Subtitle = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;

  @media screen and (min-width: 1024px) {
    margin-top: -20px;
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: inherit;
`

export const Description = styled.h4`
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  margin: 24px 0;

  @media screen and (min-width: 1024px) {
    font-size: 36px;
    line-height: 48px;
    margin: 44px 0;
  }

  small {
    display: block;
    font-size: 18px;
    line-height: 24px;
  }
`

export const ContainerThumb = styled.div`
  position: relative;
  top: -376px;
  left: 280px;
  display: none;
  transform: rotate(8deg);

  @media screen and (min-width: 600px) {
    display: block;
  }
`

export const Thumb = styled(Img)``
