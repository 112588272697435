import React from "react"

import List from "~/components/List"

import {
  Container,
  ContainerWrapper,
  ContainerWrapperItem,
  Description,
  Thumb
} from "./styles"

import Credits from "~/components/Credits"
import { useIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"

const Post = ({ ...rest }) => {
  const intl = useIntl()
  const list = ["Angular", "NGRX"]

  const image = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "projects/deco.png" }) {
        childImageSharp {
          fluid(maxWidth: 794) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container {...rest}>
      <ContainerWrapper>
        <ContainerWrapperItem>
          <Thumb fluid={image.placeholderImage.childImageSharp.fluid}
                 alt="Deco Designs website" />
        </ContainerWrapperItem>

        <div>
          <Description>
            {intl.formatMessage({ id: "deco_description" })}
          </Description>

          <List data={list} title="Stack" />

          <Credits />
        </div>
      </ContainerWrapper>
    </Container>
  )
}

export default Post
