import React from "react"
import PropTypes from "prop-types"

import {
  Container,
  Image,
  Wrapper,
  Title,
  Subtitle,
  Link,
  Description,
} from "./styles"
import List from "~/components/List"

const Card = ({ small, full, item }) => (
  <Container full={full}>
    <Image alt={item?.title} src={item?.image} loading="lazy" />

    <Wrapper>
      <Title small={small} dangerouslySetInnerHTML={{ __html: item?.title }} />

      <Subtitle>
        <Link href={item?.url} title={item?.title} target="_blank">
          {item?.urlPreview}
        </Link>
      </Subtitle>

      <Description
        small={small}
        dangerouslySetInnerHTML={{ __html: item?.description }}
      />

      {item?.list && <List title="Stack" data={item.list} />}
    </Wrapper>
  </Container>
)

Card.propTypes = {
  small: PropTypes.bool,
  full: PropTypes.bool,
  item: PropTypes.object.isRequired,
}

Card.defaultProps = {
  small: false,
  full: false,
}

export default Card
