import styled from "styled-components"
import Img from "gatsby-image"

export const Container = styled.article`
  max-width: 1388px;
  margin: 60px auto 120px;

  @media screen and (min-width: 1024px) {
    margin-top: 128px;
  }
`

export const Description = styled.h4`
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
`

export const ContainerWrapper = styled.div`
  padding: 0 1.45rem;
  
  @media screen and (min-width: 1024px) {
    text-align: left;
    padding: 0;
    display: flex;
    align-items: center;
  }
`

export const ContainerWrapperItem = styled.div`
  @media screen and (min-width: 1024px) {
    flex: 0 0 60%;
    margin-left: -30px;
    margin-right: 24px;
  }
`

export const Thumb = styled(Img)`
`
